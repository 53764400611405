<template>
  <main>
    <div class="container py-5">
      <div id="banner">
        <div
          id="carouselExampleControls"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div
                class="style_bg_img rounded-3"
                style="
                  background-image: url('/assets/img/pexels-tima-miroshnichenko-5198239.jpg');
                "
              >
                <div
                  class="style_bg_img_bloc p-5 rounded-3 d-flex flex-column justify-content-end"
                >
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-sm-8">
                        <div class="text-white">
                          <div class="animated bounce slower">
                            <h1 class="style_titre_banner">
                              Trouvez l'emploi de vos rêves
                            </h1>
                            <h5 class="text-white mb-0">
                              L'ANPE met à votre disposition une multitude
                              d'offres d'emploi crédibles et vérifées dont il a
                              la gestion ou diffusées par ses partenaires.
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="d-grid gap-2">
                          <button
                            class="btn style_btn btn-primary"
                            type="button"
                          >
                            Voir les offres
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div
                class="style_bg_img rounded-3"
                style="background-image: url('/assets/img/entreprise.jpg')"
              >
                <div
                  class="style_bg_img_bloc p-5 rounded-3 d-flex flex-column justify-content-end"
                >
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-sm-8">
                        <div class="text-white">
                          <div class="animated bounce slower">
                            <h1 class="style_titre_banner">
                              Entrepreneurs, ne cherchez plus!
                            </h1>
                            <h5 class="text-white mb-0">
                              L'ANPE met à votre disposition une multitude
                              d'offres d'emploi crédibles et vérifées dont il a
                              la gestion ou diffusées par ses partenaires.
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="d-grid gap-2">
                          <button
                            class="btn style_btn btn-primary"
                            type="button"
                          >
                            Voir les profils
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card container bg-white rounded-3 p-4 mb-5">
      <div class="row">
        <div class="col-7">
          <h4 class="mb-1">
            <strong> {{ actualite.titre }}</strong>
          </h4>
          <!-- <hr /> -->
          <!-- <table>
            <tr>
              <td class="col-9">Secteur :</td>
              <td class="col-3">Categuorie :</td>
            </tr>
            <tr>
              <td class="col-9">
                <contenu> {{ actualite.secteurs }}</contenu>
              </td>
              <td class="col-3">
                <contenu> {{ actualite.categories }}</contenu>
              </td>
            </tr>
            <tr>
              <td class="col-9 cont">Nombre de postes :</td>
              <td class="col-3"></td>
            </tr>
          </table> -->
          <hr />
          <p v-html="actualite.contenu"></p>
          <hr />
          <span
            >Publié le
            {{ actualite.datePublication | moment("DD/MM/YYYY") }}</span
          >
          <!-- <br />
          <span
            >Expiré le
            {{ actualite.dateExpiration | moment("DD/MM/YYYY") }}</span
          > -->
        </div>

        <div class="col-5">
          <img class="col-12" :src="actualite.fullImageUrl" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
//  import VueSlickCarousel from 'vue-slick-carousel'
import "vue-slick-carousel/dist/vue-slick-carousel.css";

// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { mapGetters, mapActions } from "vuex";
// import Carousel from "primevue/carousel";

export default {
  name: "Home",
  props: ["actualiteId"],
  components: {
    // VueSlickCarousel
    // Carousel,
  },
  data() {
    return {
      actualite: {},
      setting: {
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        useTransform: false,
      },
      s: {
        speed: 300,
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        useTransform: false,
        responsive: [
          {
            breakpoint: 767.98,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
            // breakpoint: 575.98,
            // settings: {
            //   slidesToShow: 1,
            //   slidesToScroll: 1,
            // }
          },
        ],
        // appendArrows: $('#button_slide_actualite'),
        prevArrow:
          '<button type="button" class="slick-prev btn btn-primary"><i class="bi bi-arrow-left"></i></button>',
        nextArrow:
          '<button type="button" class="slick-next btn btn-primary"><i class="bi bi-arrow-right"></i></button>',
      },
    };
  },
  created() {
    this.fetchActualites();
    this.fetchFaqs();
    this.getOneActualite();
  },
  computed: {
    ...mapGetters({
      actualites: "portail/actualites",
      oneActualite: "actualite/actualite",
      faqs: "portail/faqs",
    }),
  },
  methods: {
    ...mapActions({
      fetchActualites: "portail/fetchActualites",
      uneActualite: "actualite/uneActualite",
      fetchFaqs: "portail/fetchFaqs",
    }),
    getOneActualite() {
      // this.uneActualite(this.actualiteId)
      this.uneActualite(this.actualiteId).then((data) => {
        this.actualite = { ...data };
        console.log(data);
        console.log("create ---------------------------------" + data);
      });
    },
  },
};
</script>

<style>
contenu {
  color: blue;
}
</style>
